import { createSWRHook } from './_factory'

import { contentPageService } from '~/services'

export const useContentPageList = createSWRHook({
  key: 'content-page-list',
  fetcher: contentPageService.getList,
  withAuth: 'always'
})

export const useContentPageBySlug = createSWRHook({
  key: 'content-page-by-slug',
  fetcher: ({ library, slug }, token) =>
    contentPageService.getList({ slug, library }, token),
  adapter: (res) => res.data.results?.[0] ?? null,
  withAuth: 'always'
})

export const useContentPageById = createSWRHook({
  key: 'content-page',
  fetcher: contentPageService.getById,
  withAuth: 'always'
})
