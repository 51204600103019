// components
import Page from '~/components/Page'
import CatalogPage from '~/components/catalog-page'
import PromoPage from '~/components/promo-page'
import ContentPage from '~/components/content-page'
import ClientPageContainer from '~/components/ClientPageContainer'
import ClickyFloatingLogo from '~/components/ClickyFloatingLogo'
// hooks
import useTrackPageView from '~/hooks/useTrackPageView'
// services
import {
  activityService,
  libraryService,
  pageService,
  productService,
  showcaseService,
  slugService,
  userService
} from '~/services'

// i18n
import { withI18n } from '~/i18n/utils'

// ----------------------------------------------------------------------

// configure illegal paths
const PREDEFINED_PATHS = [
  // used by Clicky
  'app',
  'auth',
  'api',
  'legal',
  'payment',
  // not used by Clicky, but not supposed to be available for user
  'dashboard'
]

// ----------------------------------------------------------------------

// get SSR page data for Page, PromoPage, and ContentPage
export const getServerSideProps = withI18n(async ({ params, req }) => {
  const libraryBaseURL = params?.args?.[0]
  const pageSlug = params?.args?.[1] ?? null

  if (PREDEFINED_PATHS.includes(libraryBaseURL)) {
    return { notFound: true }
  }

  try {
    let props = {}
    props.library = await libraryService
      .getByIdOrBasePath(libraryBaseURL, { withAuthor: true })
      .then((res) => res.data.results)

    // no library, return 404
    if (!props.library) return { notFound: true }

    // if pageSlug exists, just fetch the page data
    if (pageSlug) {
      const slugWithContent = await slugService
        .getSlugContent(props.library._id, pageSlug, { withContent: true })
        .then((res) => res.data.results)

      props.type = slugWithContent.contentType
      props.page = slugWithContent.content
    } else {
      // if pageSlug doesn't exist, it means this is the library landing page
      // i.e. clicky.id/[libary.basePath]/
      // fetch main page that has isHome === true
      props.type = 'Page'
      props.page = await pageService
        .getList({ library: props.library._id, isHome: true })
        .then((res) => res.data.results?.[0])
    }

    // no page, return 404
    if (!props.type) return { notFound: true }

    // fetch attachment data for PromoPage and ContentPage
    if (props.type === 'PromoPage' || props.type === 'ContentPage') {
      const [product, relatedProductList] = await Promise.all([
        productService
          .getById(props.page.product, { withShowcase: true })
          .then((res) => res.data.results),
        showcaseService
          .getList({ author: props.library.author._id })
          .then((res) =>
            res.data.results?.filter((rp) => rp._id !== props.page.product)
          )
      ])

      props.attachment = { product, relatedProductList }
    }

    // fetch sections data for Page
    if (props.type === 'Page') {
      props.attachment = {
        profileSection: await userService
          .getProfileById(props.page.author)
          .then((res) => res.data.results)
      }
    }

    // fetch view count for Page and PromoPage
    if (props.page?.isShowViewCount) {
      props.page.viewCount = await activityService
        .getViewCount(props.page._id)
        .then((res) => res.data.results)
    }

    // for content page, trim content page data here
    // and refetch again in the client with auth
    // so we can make sure only purchased user has access to the content
    if (props.type === 'ContentPage') {
      const { page, ...rest } = props
      props = rest
    }

    return { props }
  } catch (err) {
    if (err?.response?.status === 404) {
      return { notFound: true }
    }

    if (
      err?.response?.data?.code &&
      ['user/author-not-available', 'user/author-suspended'].includes(
        err?.response?.data?.code
      )
    ) {
      return {
        redirect: {
          destination: `/suspended?from=${req.url}`,
          permanent: false
        }
      }
    }

    throw new Error(
      'There is an error when fetching page data, please try again later.'
    )
  }
})

/**
 * @param {Object} props
 * @param {'Page'|'PromoPage'|'ContentPage'} props.type
 * @param {Object} props.library
 * @param {Object} props.page
 * @param {Object} [props.attachment={}]
 * @param {Object} props.attachment.product
 * @returns {JSX.Element}
 */
export default function PageClient({ type, library, page, attachment = {} }) {
  // [ACTIVITY-TRACKER]
  useTrackPageView(
    library._id,
    type,
    type === 'Page' ? page._id : attachment.product._id
  )

  const pageTitle = type === 'Page' ? page.title : attachment.product.name

  return (
    <Page title={pageTitle}>
      <ClientPageContainer>
        {type === 'Page' && (
          <CatalogPage
            library={library}
            page={page}
            {...attachment}
          />
        )}

        {type === 'PromoPage' && (
          <PromoPage
            library={library}
            page={page}
            {...attachment}
          />
        )}

        {type === 'ContentPage' && (
          <ContentPage
            library={library}
            {...attachment}
          />
        )}

        {type !== 'PromoPage' && <ClickyFloatingLogo />}
      </ClientPageContainer>
    </Page>
  )
}
