import { createSWRHook } from './_factory'

import { invoiceService } from '~/services'

export const useInvoiceList = createSWRHook({
  key: 'invoices',
  fetcher: invoiceService.getList,
  withAuth: 'always'
})

export const useInvoiceStats = createSWRHook({
  key: 'invoices-stats',
  fetcher: invoiceService.getStats,
  withAuth: 'always'
})

export const useInvoiceAggregateAmount = createSWRHook({
  key: 'invoice-aggregate-amount',
  fetcher: invoiceService.getAggregateAmount,
  withAuth: 'always'
})

export const useInvoiceAggregateDate = createSWRHook({
  key: 'invoice-aggregate-date',
  fetcher: invoiceService.getAggregateDate,
  withAuth: 'always'
})

export const usePaymentMethodeList = createSWRHook({
  key: 'payment-method-list',
  fetcher: invoiceService.getPaymentMethods,
  withAuth: 'when-available'
})

export const useInvoice = createSWRHook({
  key: 'invoice-by-id',
  fetcher: invoiceService.getById,
  withAuth: 'when-available'
})

// multi buyer invoice
export const useMultiBuyerInvoiceList = createSWRHook({
  key: 'multibuyer-invoice-list',
  fetcher: invoiceService.getMultiBuyerInvoiceList,
  withAuth: 'always'
})

export const useMultibuyerInvoice = createSWRHook({
  key: 'multibuyer-invoice-by-id',
  fetcher: invoiceService.getMultiBuyerInvoice,
  withAuth: 'always'
})
